import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgl';

import WebGLRender from './WebGLRender'
import * as config from './config'

function logger(...args) {
    console.info(...args);
}


function printNowTime(tag, start) {
    logger(tag, (performance.now() - start).toFixed(2), "ms");
}

function createCanvas() {
    const canvas = document.createElement('canvas');
    return canvas;
}

function toInputTensor(input) {
    return tf.tidy(() => {
        let image = input instanceof tf.Tensor ? input : tf.browser.fromPixels(input);
        const cropSize = config.CROP_SIZE;
        const newImage = tf.image.resizeBilinear(image, [cropSize, cropSize])
            .expandDims(0)
            .div(255);

        return newImage;
    });
}

/**
 * 
 * @param {tf.Tensor3D} outputTensor
 * @return {Array<Number>}
 */
function toSegmentationImage(outputTensor) {
    return tf.tidy(() => {
        const [height, width] = outputTensor.shape;
        const imageTensor = tf.gather(outputTensor, [1], -1)
            .reshape([height, width])
            .mul(255)
            .toInt();

        const rawData = imageTensor.dataSync();
        const imageArray = new Uint8Array(rawData);

        return imageArray;
    });
}


class BackgroundReplacer {

    /**
     * @type {tf.GraphModel}
     */
    _model = null;
    /**
     * @type {ImageData|HTMLImageElement|HTMLCanvasElement}
     */
    _backgroundImage = null;
    /**
     * @type {ImageCapture}
     */
    _imageCapture = null;
    /**
     * @type {HTMLCanvasElement}
     */
    _canvas = null;

    /**
     * @type {WebGLRender}
     */
    _renderer = null;

    /**
     * @type {HTMLCanvasElement}
     */
    _drawCanvas = null;

    /**
     * @type {CanvasRenderingContext2D}
     */
    _drawContext = null;


    /**
     * 
     * @param {HTMLCanvasElement} renderTarget 指定结果渲染的 canvas, 可选
     */
    constructor(renderTarget) {

        if (renderTarget) {
            this._canvas = renderTarget;
        } else {
            this._canvas = createCanvas();
        }
        this._renderer = new WebGLRender(this._canvas);
        // 用于把 mediaStream 的内容画出来, 传递给 TensorFlow
        this._drawCanvas = createCanvas();
        this._drawContext = this._drawCanvas.getContext('2d');

    }

    /**
     * 加载模型和预热
     */
    async warmUp() {
        if (this._model) {
            return;
        }
        tf.enableProdMode();
        this._model = await tf.loadGraphModel(config.MODEL_URL);
        tf.tidy(() => {
            const buffer = tf.buffer([1, 224, 224, 3]);
            tf.squeeze(this._model.execute(buffer.toTensor()));
        });
        console.info('Model warm up finished.');
    }

    /**
     * 使用 MediaStream 进行进行绑定, 或获得新的 MediaStream, 用于预览
     * @param {MediaStream} mediaStream 
     * @return {MediaStream} 新的 mediaStream, start 之后会开始输出背景替换后的效果
     */
    bind(mediaStream) {
        const track = mediaStream.getVideoTracks()[0];
        const setting = track.getSettings();
        this._drawCanvas.width = setting.width;
        this._drawCanvas.height = setting.height;
        // const video = document.createElement('video');
        // video.width = this._drawCanvas.width;
        // video.height = this._drawCanvas.height;
        // video.autoplay = true;
        // video.srcObject = mediaStream;
        // this._video = video;
        this._imageCapture = new ImageCapture(track);
        const newStream = this._canvas.captureStream();
        return newStream;
    }

    /**
     * 设置要显示的新背景
     * @param {ImageData|HTMLImageElement|HTMLCanvasElement} newImage 
     */
    setBackgroundImage(newImage) {
        this._backgroundImage = newImage;
    }

    /**
     * 
     * @param {{callback(loopFps: Number, renderFps: Number): Function}} param
     */
    setDebug({ callback }) {
        this._debugCallback = callback;
    }

    /**
     * 检查是否已经ready 可用
     */
    isReady() {
        return this._model && this._renderer.haveInit && this._imageCapture && this._backgroundImage;
    }

    /**
     * 启动替换逻辑, 调用前, 必须确保调用过 bind 进行 MediaStream 绑定, 已经 setBackgroundImage 设置过被替换的图片
     */
    start() {
        if (!this.isReady()) {
            console.error('Model or WebGLRender is NOT ready !');
            return;
        }
        this._isStart = true;
        this._loop();
    }

    /**
     * 停止 loop
     */
    stop() {
        this._isStart = false;
    }

    _loop() {
        if (!this._isStart) {
            return;
        }
        let start = performance.now();

        if (this._imageCapture.track.readyState == "live") {
            // 视频没 ready ,就不要跑了
            this._imageCapture.grabFrame().then(inputBitmap => {
                this._drawCanvas.width = inputBitmap.width;
                this._drawCanvas.height = inputBitmap.height;
                this._drawContext.drawImage(inputBitmap, 0, 0);
                printNowTime("getImage", start);

                this._run(inputBitmap);
            }).catch(error => {
                // console.error("getImage", error);
            });
        }

        requestAnimationFrame(() => this._loop());

        // 计算 loop fps
        if (this._debugCallback) {
            if (!this._loopTotalCount) {
                this._loopTotalCount = 0;
                this._lastLoopTime = 0;
                this._loopSumTime = 0;
            }
            if (this._lastLoopTime) {
                this._loopSumTime += start - this._lastLoopTime;
                this._loopFps = 1000 / (this._loopSumTime / this._loopTotalCount);
            }
            this._loopTotalCount++;
            this._lastLoopTime = start;
            this._debugCallback(this._loopFps || 0, this._renderFps || 0);
        }

    }


    /**
     * 执行背景替换主逻辑
     * @param {ImageBitmap} input 
     */
    async _run(input) {
        let start = performance.now();
        const allStart = start;

        if (!this._runModelCount) {
            this._runModelCount = 0;
        }
        this._runModelCount = (this._runModelCount + 1) % 2;
        let segmentImageData = null;
        if (this._runModelCount) {
            const inputTensor = toInputTensor(this._drawCanvas);
            printNowTime("toInputTensor", start);

            start = performance.now();
            const outputTensor = tf.tidy(() => tf.squeeze(this._model.execute(inputTensor)));
            printNowTime("model.execute", start);

            start = performance.now();
            segmentImageData = toSegmentationImage(outputTensor);
            printNowTime("toSegmentationImage", start);
            
            tf.dispose(inputTensor);
            tf.dispose(outputTensor);
        }

        start = performance.now();
        this._renderer.updateBackground(this._backgroundImage);
        if (segmentImageData) {
            this._renderer.updateSegment(segmentImageData, config.CROP_SIZE, config.CROP_SIZE);
        }

        this._renderer.draw(input);
        printNowTime("toReplacedImage", start);

        input.close && input.close(); // 不释放会有显存泄漏
        
        printNowTime("===All===", allStart);

        // 计算 render fps
        if (this._debugCallback) {
            if (!this._renderTotalCount) {
                this._renderTotalCount = 0;
                this._lastRenderTime = 0;
                this._renderSumTime = 0;
            }
            if (this._lastRenderTime) {
                this._renderSumTime += performance.now() - allStart;
                this._renderFps = 1000 / (this._renderSumTime / this._renderTotalCount);
            }
            this._renderTotalCount++;
            this._lastRenderTime = allStart;
            this._debugCallback(this._loopFps || 0, this._renderFps || 0);
        }
    }

    async dispose() {
        if (this._model) {
            this._model.dispose();
        }
    }
}

export default BackgroundReplacer;