
const VSHADER_SOURCE =
    `
attribute vec2 aTexCoordinate;
attribute vec4 aPosition;
varying vec2 vTexCoord;
void main() {
    vTexCoord = vec2(aTexCoordinate.x, 1.0 - aTexCoordinate.y);
    gl_Position = aPosition;
}
`

const FSHADER_SOURCE =
    `
precision mediump float;
varying vec2 vTexCoord;
uniform sampler2D uTexture;
uniform sampler2D uTextureSegment;
uniform sampler2D uTextureBackground;
void main()
{

    vec4 texColor =  texture2D(uTexture, vec2(1.0 - vTexCoord.x, vTexCoord.y));
    vec4 maskColor = texture2D(uTextureSegment, vec2(1.0 - vTexCoord.x, vTexCoord.y));
    vec4 bgColor = texture2D(uTextureBackground, vTexCoord);
    float alpha = maskColor.r;
    if(alpha > 0.499){
        alpha = 1.0;
    }else if(alpha < 0.01){
        alpha = 0.0;
    }
    gl_FragColor = mix(bgColor, texColor, alpha);
}
`



class WebGLRender {
    /**
     * @type {WebGLRenderingContext}
     */
    gl = null
    haveInit = false
    glProgram = null
    vShader = null
    fShader = null

    vertexBuffer = null
    vertexIndiceBuffer = null
    trianglesTexCoordBuffer = null
    textureUniform = null
    maskUniform = null
    backgroundUniform = null

    texture = null
    maskTexture = null
    backgroundTexture = null

    canvas = null


    vertex = [
        -1, -1, 0,
        1, -1, 0,
        1, 1, 0,
        -1, 1, 0,
    ]

    vertexIndice = [
        0, 1, 2,
        0, 2, 3
    ]

    texCoords = [
        0.0, 0.0,
        1.0, 0.0,
        1.0, 1.0,
        0.0, 1.0
    ];

    flipTexCoords = [
        1.0, 0.0,
        0.0, 0.0,
        0.0, 1.0,
        1.0, 1.0
    ]

    constructor(canvas) {
        if (canvas) {

            this.canvas = canvas

            let ret = false
            do {
                ret = this.initGL(canvas); if (!ret) { console.error('initGL failed'); break }
                ret = this.initShaders(); if (!ret) { console.error('initShaders failed'); break }
                ret = this.initUniforms(); if (!ret) { console.error('initUniforms failed'); break }

            } while (false)
            if (ret) {
                this.initTextureAttr()
                this.initTexture()
            }
            this.haveInit = ret
        }

    }


    initGL(canvas) {
        try {
            this.gl = canvas.getContext('webgl2')
        } catch (e) {
            console.error('gl init failed', e)
            return false
        }
        if (this.gl) {
            return true
        } else {
            return false
        }
    }
    initShaders() {


        // compile shaders
        const vertexShader = this.makeShader(VSHADER_SOURCE, this.gl.VERTEX_SHADER)
        const fragmentShader = this.makeShader(FSHADER_SOURCE, this.gl.FRAGMENT_SHADER)
        this.vShader = vertexShader
        this.fShader = fragmentShader
        if (!vertexShader || !fragmentShader) {
            return false
        }

        // create program
        this.glProgram = this.gl.createProgram()

        // attach and link shaders to the program
        this.gl.attachShader(this.glProgram, vertexShader)
        this.gl.attachShader(this.glProgram, fragmentShader)
        this.gl.linkProgram(this.glProgram)

        if (!this.gl.getProgramParameter(this.glProgram, this.gl.LINK_STATUS)) {
            console.error('Unable to initialize the shader program.')
            return false
        } else {
            // use program
            this.gl.useProgram(this.glProgram)
            return true
        }
    }
    makeShader(src, type) {
        //compile the vertex shader
        const shader = this.gl.createShader(type)
        this.gl.shaderSource(shader, src)
        this.gl.compileShader(shader)

        if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
            console.error(`Error compiling shader: ${this.gl.getShaderInfoLog(shader)}`)
            return null
        }
        return shader
    }

    initUniforms() {
        this.textureUniform = this.gl.getUniformLocation(this.glProgram, 'uTexture')
        this.backgroundUniform = this.gl.getUniformLocation(this.glProgram, 'uTextureBackground')
        this.maskUniform = this.gl.getUniformLocation(this.glProgram, 'uTextureSegment')
        return true
    }

    createTexture() {
        const tex = this.gl.createTexture()
        this.gl.activeTexture(this.gl.TEXTURE0)
        this.gl.bindTexture(this.gl.TEXTURE_2D, tex)
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR)
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR)
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE)
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE)
        return tex;
    }

    initTexture() {
        this.texture = this.createTexture();
        this.maskTexture = this.createTexture();
        this.backgroundTexture = this.createTexture();
    }

    initTextureAttr() {
        // vertex data
        const vertexBuffer = this.gl.createBuffer()
        this.vertexBuffer = vertexBuffer;
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexBuffer)
        this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.vertex), this.gl.STATIC_DRAW)

        // indice data
        const vertexIndiceBuffer = this.gl.createBuffer()
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, vertexIndiceBuffer)
        this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(this.vertexIndice), this.gl.STATIC_DRAW)

        // set position attribute
        const aVertexPosition = this.gl.getAttribLocation(this.glProgram, 'aPosition')
        this.gl.vertexAttribPointer(aVertexPosition, 3, this.gl.FLOAT, false, 0, 0)
        this.gl.enableVertexAttribArray(aVertexPosition)

        // texture coordinate data
        const trianglesTexCoordBuffer = this.gl.createBuffer()
        this.trianglesTexCoordBuffer = trianglesTexCoordBuffer;
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, trianglesTexCoordBuffer)
        this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.texCoords), this.gl.STATIC_DRAW)

        const vertexTexCoordAttribute = this.gl.getAttribLocation(this.glProgram, 'aTexCoordinate')
        this.gl.vertexAttribPointer(vertexTexCoordAttribute, 2, this.gl.FLOAT, false, 0, 0)
        this.gl.enableVertexAttribArray(vertexTexCoordAttribute)
    }

    bindTexture(texture, texUniform, texIndex = 0) {
        this.gl.activeTexture(this.gl.TEXTURE0 + texIndex)
        this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
        this.gl.uniform1i(texUniform, texIndex)
    }
    updateInput(image) {
        this.bindTexture(this.texture, this.textureUniform, 0);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, image.naturalWidth || image.width, image.naturalHeight || image.height, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image)
    }
    updateSegment(segment, width, height) {
        if (this.segment != segment) {
            this.bindTexture(this.maskTexture, this.maskUniform, 1);
            this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.R8, width, height, 0, this.gl.RED, this.gl.UNSIGNED_BYTE, segment);
            this.segment = segment;
        }
    }
    updateBackground(image) {
        if (this.backgroundImage != image) {
            this.bindTexture(this.backgroundTexture, this.backgroundUniform, 2);
            this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, image.naturalWidth || image.width, image.naturalHeight || image.height, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image)
            this.backgroundImage = image;
        }
    }
    resize(width, height) {

        if (this.canvas.width != width || this.canvas.height != height) {

            this.canvas.width = width
            this.canvas.height = height
        }
    }

    /**
     * 
     * @param {ImageBitmap} image 
     */
    draw(image) {
        if (!this.haveInit) {
            console.error('not init gl. can not draw')
            return
        }
        this.updateInput(image);
        const width = image.width;
        const height = image.height;

        this.resize(width, height)

        const canvasWidth = this.canvas.width
        const canvasHeight = this.canvas.height
        this.gl.viewport(0, 0, canvasWidth, canvasHeight)

        this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0)

    }

}

export default WebGLRender;