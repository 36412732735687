
import 'bulma/css/bulma.css';

import BackgroundReplacer from './BackgroundReplacer';

import { openCamera } from './video'

import backgroundImage from './bgs/pretty.jpg';


const bgImg = new Image();
bgImg.src = backgroundImage;

/**
 * @type {BackgroundReplacer}
 */
let replacer = null;

function initialize() {
  const canvas = document.getElementById('output-image');
  replacer = new BackgroundReplacer(canvas);

  const debugText = document.getElementById('status-message');
  replacer.setDebug({
    callback: (loopFps, renderFps) => {
      debugText.innerText = `Loop FPS: ${loopFps.toFixed(2)} | Render FPS: ${renderFps.toFixed(2)}`
    }
  });

  const message = document.getElementById('message');
  const video = document.getElementById('local-video');
  document.getElementById('open-camera').onclick = function (evt) {
    message.innerText = '加载中...';
    openCamera(video).then(() => {
      message.innerText = '摄像头打开成功';
    })
  }

  document.getElementById('load-model').onclick = function (evt) {
    message.innerText = '加载中...';
    replacer.warmUp().then(() => {
      message.innerText = '模型加载成功';
    });
  }

  document.getElementById('run-model').onclick = function (evt) {
    if (!window.stream) {
      message.innerText = '摄像头还没打开呢';
      return;
    }
    if (replacer.isReady()) {
      message.innerText = '等一会, 模型还没加载完成';
      return;
    }
    const newStream = replacer.bind(window.stream);
    // video.srcObject = newStream;
    replacer.setBackgroundImage(bgImg);
    replacer.start();
    message.innerText = '运行中';
  }

  document.getElementById('switch-preview').onclick = function (evt) {
    if (video.srcObject) {
      video.srcObject = null;
      this.innerText = '打开摄像头预览';
    } else {
      video.srcObject = window.stream;
      this.innerText = '关闭摄像头预览';
    }
  }

  message.innerText = '准备好了';
}

document.addEventListener('DOMContentLoaded', initialize);








