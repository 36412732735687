
async function openCamera(video) {
    var constraints = window.constraints = {
        audio: false,
        video: {
            width: 360,
            height: 640,
            frameRate: 20
        }
    };

    return navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
            var videoTracks = stream.getVideoTracks();
            console.log('Got stream with constraints:', constraints);
            console.log('Using video device: ' + videoTracks[0].label);
            stream.onended = function () {
                console.log('Stream ended');
            };
            window.stream = stream; // make variable available to browser console
            video && (video.srcObject = stream);
        })
        .catch(function (error) {
            if (error.name === 'ConstraintNotSatisfiedError') {
                console.error('The resolution ' + constraints.video.width.exact + 'x' +
                    constraints.video.width.exact + ' px is not supported by your device.');
            } else if (error.name === 'PermissionDeniedError') {
                console.error('Permissions have not been granted to use your camera and ' +
                    'microphone, you need to allow the page access to your devices in ' +
                    'order for the demo to work.');
            }
            console.error('getUserMedia error: ' + error.name, error);
        });
}

export { openCamera };